<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        :color="accountDataLocale.avatar ? '' : 'primary'"
        :class="accountDataLocale.avatar ? '' : 'v-avatar-light-bg primary--text'"
        size="120"
        class="me-6"
      >
        <v-img
          v-if="accountDataLocale.avatar"
          :src="accountDataLocale.avatar"
          :lazy-src="require(`@/assets/images/avatars/1.png`)"
        ></v-img>
        <span
          v-else
          class="font-weight-semibold text-5xl"
        >{{ avatarText(accountDataLocale.name) }}</span>
      </v-avatar>

      <!-- upload photo -->
      <div class="me-3 mt-5">
        <file-upload
          v-model="accountDataLocale.avatar"
          add-class="mx-auto justify-center"
          :label="$t('Upload new photo')"
          folder="images/users/avatars/"
          :btn="true"
          :extra="false"
          :hide-label="false"
          icon-name="mdi-pencil"
          :icon="false"
          :show-download="false"
          inputname="file"
          :crop="true"
          :aspect-ratio="1"
          :max-crop-height="300"
          :max-crop-width="300"

          accept="image/*"
          :event="saveAvatar"
        >
          <template #btn-label>
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">{{ $t("Chargeur la photo") }}</span>
          </template>
        </file-upload>
      </div>
    </v-card-text>

    <v-card-text>
      <user-form
        v-model="accountDataLocale"
        :is-form-active="false"
        :role-options="[]"
        :plan-options="[]"
        :with-password="false"
        @refetch-data="resetForm"
      >
        <template v-slot:form-title>
          <span></span>
        </template>
        <template #form-cancel>
          <span></span>
        </template>
      </user-form>
      <!--      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.username"
              label="Username"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.email"
              label="E-mail"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.role"
              dense
              label="Role"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="accountDataLocale.status"
              dense
              outlined
              label="Status"
              :items="status"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.company"
              dense
              outlined
              label="Company"
            ></v-text-field>
          </v-col>

          &lt;!&ndash; alert &ndash;&gt;
          <v-col cols="12">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Your email is not confirmed. Please check your inbox.
                  </p>
                  <a
                    href="javascript:void(0)"
                    class="text-decoration-none warning&#45;&#45;text"
                  >
                    <span class="text-sm">Resend Confirmation</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
            >
              Save changes
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>-->
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UserForm from '@/views/apps/user/user-list/UserForm'
import FileUpload from '@/components/file_upload'
import store from '@/store'

export default {
  components: { FileUpload, UserForm },
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    // const accountDataLocale = ref(JSON.parse(JSON.stringify(props.accountData)))
    const accountDataLocale = computed({
      get: () => props.accountData,
      set: value => emit('update:account-data', value),
    })
    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    }
    const saveAvatar = (e, data) => {
      console.log('save avatar', data)
      store.dispatch('app-user/updateAvatar', data.url)

      accountDataLocale.value.avatar = data.url
    }

    return {
      status,
      accountDataLocale,
      saveAvatar,
      avatarText,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
