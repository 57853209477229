import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useUsersList() {
  const blankItem = {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    status: null,
    post: null,
    avatar: null,
    user_id: null,
    role_id: null,
    password: null,
    changePassword: true,
    quota: 100,
    roles_names: [],
  }
  const form = ref(null)
  const items = ref([])
  const filters = ref([
    { text: 'Utilisateur', value: 'name', filter: { place_holder: '', type: 'text' } },
    { text: 'Code', value: 'code', filter: { place_holder: '', type: 'text' } },
    { text: 'E-mail', value: 'email', filter: { place_holder: '', type: 'text' } },
    {
      text: 'Rôle',
      value: 'long_roles_names',
      sortable: false,
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state['app-user'].roles,
        label: 'display_name',
        id: 'name',
        multiple: true,
        column: 'users.role_id',
        join_table: 'roles',
        join_column: 'roles.id',
        local_column: 'users.role_id',
      },
    },
    { text: 'Poste', value: 'post', filter: { place_holder: '', type: 'text' } },
    {
      text: 'Statut',
      value: 'status',
      filter: {
        place_holder: '', type: 'select', column: 'status', values: [{ id: 1, label: 'Actif' }, { id: 0, label: 'Inactif' }], label: 'label', id: 'id', multiple: false,
      },
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])
  const item = ref({})
  const tableColumns = [
    { text: 'UTILISATEUR', value: 'name' },
    { text: 'Code', value: 'code' },
    { text: 'E-MAIL', value: 'email' },
    { text: 'ROLE', value: 'role', sortable: false },
    { text: 'POST', value: 'post' },
    { text: 'STATUS', value: 'status' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const roleFilter = ref(null)
  const supervisorFilter = ref(null)
  const statusFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const userTotalLocal = ref([])
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
      item.value.changePassword = false
      item.value.roles_names = item.value.roles_names || []
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
      item.value.roles_names = item.value.roles_names || []
    isFormActive.value = !isFormActive.value
  }
  // store
  //   .dispatch('app-user/fetchRoles', {})

  // fetch data
  const fetchUsers = () => {
    isFormActive.value = false
    store
      .dispatch('app-user/fetchUsers', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })),

        term: searchQuery.value,
        status: statusFilter.value,
        role: roleFilter.value,
        supervisor: supervisorFilter.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        const { data, total, userTotal } = response.data

        items.value = data.map(object => {
          object.changePassword = false
          /*object.role = object.roles.length > 0 ? object.roles[0] : null*/
          object.loading = false

          return object
        })
        store.commit('app-user/USERS', items.value)
        totalItems.value = total
        userTotalLocal.value = userTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-user/deleteUser', item.value)
        .then(response => {
          loading.value = false
          fetchUsers()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }

  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchUsers()
    }, 500)
  }, { deep: true })

  watch([searchQuery, roleFilter, supervisorFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchUsers()
  }, { deep: true })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'administrator') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'administrator') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusName = status => {
    if (status === 0) return 'Inactif'
    if (status === 1) return 'Actif'

    return 'Actif'
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    item,
    items,
    tableColumns,
    searchQuery,
    roleFilter,
    supervisorFilter,
    statusFilter,
    filters,
    totalItems,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    isFormActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    resetForm,
    openDialog,
    deleteDialog,
    confirmDelete,
    deleteItem,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusName,
    resolveUserTotalIcon,
  }
}
