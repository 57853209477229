<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Ajouter un manuscrit") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 600px;"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.title"
              outlined
              dense
              :error-messages="errorMessages.title"
              :label="$t('Titre')"
              :placeholder="$t('Titre')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.author_id"
              :rules="[validators.required]"
              :error-messages="errorMessages.author"
              :label="$t('Auteur')"
              item-text="full_name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              :items="authors"
              :loading="isLoadingAuthors"
              :search-input.sync="search"
              clearable
              @change="searchAuthor"
            >
              <template #item="{ item }">
                <AvatarName :options="{avatar: item.avatar, label: item.full_name }" />
              </template>
              <template #selection="{ item }">
                <AvatarName :options="{avatar: item.avatar, label: item.full_name }" />
              </template>
              <template #no-data>
                <span>{{ $t("L'auteur.") }} <strong>{{ search }}</strong> {{ $t("n'existe pas.") }}</span>
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="newAuthor"
                >
                  <span>{{ $t("Créer l'auteur") }}</span>
                </v-btn>
              </template>
            </v-autocomplete>
            <v-dialog
              v-model="isFormAuthorActive"
              touchless
              :right="!$vuetify.rtl"
              :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
            >
              <author-form
                v-model="item.author"
                :is-form-active="isFormAuthorActive"
                :with-addresses="false"
                @refetch-author="updateAuthors"
              >
                <template v-slot:form-title>
                  <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter un auteur') }}</span>
                  <v-spacer />
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    small
                    @click="isFormAuthorActive = !isFormAuthorActive"
                  >
                    <v-icon size="22">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>
                </template>
                <template #form-cancel>
                  <v-btn
                    color="secondary"
                    outlined
                    type="reset"
                    @click="isFormAuthorActive = !isFormAuthorActive"
                  >
                    {{ $t('Annuler') }}
                  </v-btn>
                </template>
              </author-form>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.genre_id"
              :label="$t('Genre')"
              :error-messages="errorMessages.genre_id"
              :items="$store.state['app'].genres"
              item-text="label"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.sum_words"
              type="number"
              outlined
              min="0"
              dense
              :label="$t('Nombre de mots')"
              :placeholder="$t('Nombre de mots')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.sum_pages"
              type="number"
              outlined
              :rules="[ item.custom_pages ?validators.required:true,]"
              :error-messages="errorMessages.sum_pages"
              :label="$t('Nombre de pages')"
              :placeholder="$t('Nombre de pages')"
              :readonly="!item.custom_pages"
              :append-icon="item.custom_pages ? icons.mdiPencilOffOutline : icons.mdiPencilOutline"
              dense
              hide-details="auto"
              @click:append="item.custom_pages = !item.custom_pages"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.public_price"
              type="number"
              outlined
              :rules="[ item.custom_price ?validators.required:true,]"
              :error-messages="errorMessages.public_price"
              :label="$t('Prix public')"
              :readonly="!item.custom_price"
              :append-icon="item.custom_price ? icons.mdiPencilOffOutline : icons.mdiPencilOutline"
              dense
              hide-details="auto"
              @click:append="item.custom_price = !item.custom_price"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.sum_copies"
              outlined
              type="number"
              min="0"
              dense
              :label="$t('Nombre d\'exemplaires')"
              :placeholder="$t('Nombre d\'exemplaires')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.discount_price"
              outlined
              type="number"
              min="0"
              dense
              :error-messages="errorMessages.discount_price"
              :label="$t('Prix remisé')"
              :placeholder="$t('Prix remisé')"
              :readonly="true"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.source_id"
              :label="$t('Source')"
              :rules="[validators.required]"
              :error-messages="errorMessages.source_id"
              :items="$store.state['app'].sources"
              item-text="source_name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.format_id"
              :label="$t('Format')"
              :error-messages="errorMessages.format_id"
              :items="$store.state['app'].formats.filter((ele)=>ele.format_type === 'Manuscript' )"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <!--          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.country_id"
              :label="$t('Pays')"
              :error-messages="errorMessages.country_id"
              :items="$store.state['app'].pays"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>-->
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-autocomplete
              v-model="item.origin_id"
              :label="$t('Origine')"
              :rules="[validators.required]"
              :error-messages="errorMessages.origin_id"
              :items="$store.state['app'].origins"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <date-picker
              v-if="isFormActive"
              v-model="item.receipt_date"
              :date-item="item.receipt_date"
              :rules="[validators.required]"
              :max-date="minDate"
              :label="$t('Date de réception')"
            />
          </v-col>
          <v-col
            v-if="item.id"
            cols="12"
            sm="12"
            md="6"
          >
            <date-picker
              v-model="item.proposal_date"
              :max-date="null"
              :label="$t('Date de proposition')"
            />
          </v-col>
          <v-col
            v-if="item.id && item.proposal_date"
            cols="12"
            sm="12"
            md="6"
          >
            <date-picker
              v-model="item.relaunch_date"
              :max-date="minDate"
              :label="$t('Date de relance')"
            />
          </v-col>
          <v-col
            v-if="item.id"
            cols="12"
            sm="12"
            md="6"
          >
            <v-select
              v-model="item.is_read"
              :items="['A', 'B','C','D']"
              :label="$t('Lecture')"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            :disabled="isME"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { getIndex, getItem } from '@core/utils'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker, AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const {} = authorsController()
    const errorMessages = ref({ })
    const valid = ref(false)
    const modalReceipt = ref(false)
    const modalResponse = ref(false)
    const modalProposition = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingManuscripts = ref(false)
    const authors = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    if (item.value.author && authors.value.findIndex(ele => ele.id === item.value.author_id) < 0) {
      authors.value.push(item.value.author)
    }
    watch(item, val => {
      if (item.value.author && authors.value.findIndex(ele => ele.id === item.author_id) < 0) {
        authors.value.push(item.value.author)
      }
    }, { deep: true })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const formatDate = (date, f) => format(date, f)
    const newAuthor = () => {
      item.value.author = {
        full_name: search.value, email: null, phone: null, addresses: [],
      }
      isFormAuthorActive.value = true
    }
    const searchAuthors = term => {
      store.dispatch('app/searchAuthors', { term, rowsPerPage: 100 }).then(response => {
        isLoadingAuthors.value = false
        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = authors.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            authors.value.push(u)
          }
        }
      })
    }
    axios.get('/fees').then(response => {
      fees.value = response.data.data
    })
    const updateAuthors = author => {
      console.log(author)
      isFormAuthorActive.value = false
      authors.value.push(author)
      item.value.author = author
      item.value.author_id = author.id
    }

    const onSubmit = () => {
      if (valid.value) {
        if (!item.value.receipt_date) {
          isSnackbarBottomVisible.value = true
          snackBarMessage.value = 'Veuillez entrer la date de réception'

          return false
        }
        loading.value = true
        store.dispatch('app-manuscript/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    searchAuthors('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingAuthors.value) return ''
      isLoadingAuthors.value = true
      searchAuthors(val)

      return ''
    })
    const nbPages = computed({
      get: () => item.value.sum_pages,

      /* set: value => emit('update:object', value), */
    })
    const nbWords = computed({
      get: () => item.value.sum_words,
    })
    const publicPrice = computed({
      get: () => item.value.public_price,
    })
    const authorIdChanged = computed({
      get: () => item.author_id,
    })
    const calculateDiscount = () => {
      if (!item.value.custom_discount) {
        item.value.discount_price = Math.ceil(item.value.public_price * 0.7) + 1
      }
    }

    const calculatePrice = () => {
      const fee = fees.value.find(i => i.page_min <= nbPages.value && i.page_max >= nbPages.value)

      if (fee && (nbPages.value)) {
        const price = parseFloat(fee.frais)
        const sub = parseInt(nbPages.value - fee.subtraction, 10)
        item.value.public_price = parseFloat((price + parseFloat((sub * fee.percentage).toFixed(2))).toFixed(2))
      } else {
        item.value.public_price = store.state.app.settings.default_pages_price || 27
      }
    }
    const calculatePages = () => {
      // item.value.sum_pages = null
      if (!item.value.custom_pages && item.value.sum_words >= 0) {
        const words = store.state.app.settings.number_words_per_page || 260
        item.value.sum_pages = Math.ceil(item.value.sum_words / words)
      } else {
        console.log('test')
        item.value.sum_pages = null
      }
    }
    const isME = ref(false)
    const isLoadingAuthor = ref(false)

    const searchAuthor = id => {
      isME.value = false
      errorMessages.value.author = null
      isLoadingAuthors.value = true
      store.dispatch('app/searchAuthor', id ).then(response => {
        isLoadingAuthors.value = false
        if (response.data && response.data.manuscripts) {
          if (response.data.manuscripts.filter((e) => store.state.app.settings.manuscript_me_denied_statuses.includes(e.status_id)).length) {
            isME.value = true
            errorMessages.value.author = 'Les manuscrits de cet auteur sont refusés par la maison'
          }
        }
      })
    }
    watch(authorIdChanged, val => {
      console.log('value',val)
      searchAuthor(val)
      item.value.country_id = authors.value.find(i => i.id === val).country_id

      return ''
    })
    watch(nbPages, val => {
      calculatePrice()

      return ''
    })
    watch(nbWords, val => {
      calculatePages()

      return ''
    })
    watch(publicPrice, val => {
      calculateDiscount()

      return ''
    })

    store
      .dispatch('app/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchCountries', { per_page: 500 })

    return {
      searchAuthor,
      isLoadingAuthor,
      isME,
      minDate,
      resetItem,
      form,
      isFormAuthorActive,
      isLoadingManuscripts,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      isLoadingAuthors,
      onSubmit,
      updateAuthors,
      modalReceipt,
      modalProposition,
      modalResponse,
      formatDate,
      newAuthor,
      item,
      valid,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
