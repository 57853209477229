<template>
  <v-dialog
    v-model="dialog"
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        class=" me-3"
        dark
        v-bind="attrs"
        v-on="on"
        @click="$emit('refetch-data',true)"
      >
        <v-icon>{{ icons.mdiMicrosoftExcel }}</v-icon>
        <span>{{ title }}</span>
      </v-btn>
    </template>
    <v-card :loading="item.loading">
      <v-card-title>
        <span class="font-weight-semibold text-base text--primary">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="dialog = !dialog"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row
          align-content="center"
          justify="center"
        >
          <v-col cols="12">
            <v-btn
              block
              color="info"
              :loading="item.loading"
              target="_blank"
              :href="item.url"
            >
              <v-icon left>
                {{ icons.mdiMicrosoftExcel }}
              </v-icon>
              {{ $t('Télécharger') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiMicrosoftExcel,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCheckOutline,
} from '@mdi/js'
import { i18n } from '@/plugins/i18n'
// eslint-disable-next-line object-curly-newline

export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    title: { type: String, default: 'Export Excel' },
  },
  setup(props, { emit }) {
    const dialog = ref(false)
    const item = computed({
      get: () => props.options,
      set: value => emit('update:object', value),
    })
    watch([item], () => {
    }, { deep: true })

    return {
      item,
      dialog,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiMicrosoftExcel,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCheckOutline,
      },
    }
  },
}
</script>
